@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply bg-white;
	@apply dark:bg-dark;
}

button {
	color: black;
	background-color: #c7c7c7;
}
